jQuery(document).foundation();
jQuery(function($){
		//$.asPieProgress.noConflict();
	if ($('.pie_progress')[0]){ 
		 $('.pie_progress').asPieProgress({
			namespace: 'pieProgress',
			speed: 100,
			numberCallback(n) {
				'use strict';
				const percentage = Math.round(this.getPercentage(n));
				return `${percentage}<small>%</small>`;
			  },
		  });
		var time = 0;
		$('.pie_progress').each(function(index) {
			var self = this
			 setTimeout(function() {
				console.log(self);
				$(self).asPieProgress('start'); 
			}, time);
			time += 500;
			//$(this).asPieProgress('start');
		});
	}
	

	//scroll top
	// scroll to top
	$(document).on( 'scroll', function(){
		if ($(window).scrollTop() > 100) {
			$('.scroll-top-wrapper').addClass('show');
		} else {
			$('.scroll-top-wrapper').removeClass('show');
		}
	});
	$('.scroll-top-wrapper').on('click', scrollToTop);
	
	function scrollToTop() {
		var verticalOffset = typeof(verticalOffset) != 'undefined' ? verticalOffset : 0;
		var element = $('body');
		var offset = element.offset();
		var offsetTop = offset.top;
		$('html, body').animate({scrollTop: offsetTop}, 500, 'linear');
	} 
	
	//isotope vars
	var filterValue = false;
	var filterApplied = false;
	var pid;
	var found;
	var filterIDs = [];
	var initialQuery = misha_loadmore_params.posts;
	var initialIDs = [];
	var filterElems;
	var qsRegex = false;
	var lcqs;
	var buttonFilter;
	var postType;

	//setup iso grid
	var $grid = $('.filter-grid').isotope({
		itemSelector: '.cell',
		percentPosition: true,
		masonry: {
			// use element for option
			columnWidth: '.filter-sizer.cell'
		},
		filter: function() {
			var $this = $(this);
			//var str = str.replace(/\s+/g, '-').toLowerCase();
			var searchResult = lcqs ? lcqs.test( $this[0].classList ) : true;
			//var tagResult = qsRegex ? $this.class().match( 'tag-' + qsRegex ) : true;
			var buttonResult = buttonFilter ? $this.is( buttonFilter ) : true;
			
			//var filterinfo = itemElem.getElementsByClassName('filterinfo');
			//var filtertitle = itemElem.getElementsByTagName('h2');
			//var scope = $this;
			//console.log($this[0].classList);
			//console.log(searchResult);
			if(!searchResult){
				searchResult = qsRegex ? $this.text().match( qsRegex ) : true;
			}
			//return tagResult;
			
			return searchResult && buttonResult;
		},
		})
				
	//imagesloaded triiger iso
	$grid.imagesLoaded().progress( function() {
		$grid.isotope('layout');
	});
	//gather initial element post IDs
	var initialElems = $grid.isotope('getItemElements');
	$.each(initialElems, function( index, value ) {
		if(value.hasAttribute('id')){
			pid = $(value).attr('id').replace(/post-/, '');
			initialIDs.push(pid);
		}
	});
	// bind filter button click
	$('.filter .button-group').on( 'click', 'button', function() {
		//get filter value for iso
		filterValue = $( this ).attr('data-filter');
		//push filter to buttonFilter
		buttonFilter = filterValue;
		
		if ( filterValue!="*" ){
			//get value for loadmore
			var filter_str = $( this ).attr('data-filter');
			filter_str = filter_str.substring(filter_str.indexOf("-") + 1);
			console.log(filter_str);
			filterApplied = filter_str;
		} else {
			filterApplied = false;
			var $remove = $grid.find('.ajax-loaded');
			$grid.isotope( 'remove', $remove )
		}
		
		$('.misha_loadmore').removeClass('hide');
		$('#end-box').addClass('hide');
		$('#msg-box').addClass('hide');
		//filter iso
		$grid.isotope();
		//get Filter Elements
		filterElems = $grid.isotope('getFilteredItemElements');
		//console.log(filterElems);
		$.each(filterElems, function( index, value ) {
				var method = index % 2 ? 'removeClass' : 'addClass';
				if($('body').hasClass('post-type-archive-service')){
					$( value )[ method ]('service-grey-archive');
				} else {
					$( value )[ method ]('post-text-archive');
				}
		});
		//no results message
		if ( !$grid.data('isotope').filteredItems.length ) {
			$('#msg-box').removeClass('hide');
		} else {
			$('#msg-box').addClass('hide');
		}
	});
	// change is-checked class on buttons
	$('.filter  .button-group').each( function( i, buttonGroup ) {
		var $buttonGroup = $( buttonGroup );
		$buttonGroup.on( 'click', 'button', function() {
			$buttonGroup.find('.is-checked').removeClass('is-checked');
			$('.sub-filter:visible').addClass('hide');
			$( this ).addClass('is-checked');
			//hide/show sub filter
			//var activeChild = $( this ).is('[aria-expanded]');
			//var tog = activeChild.attr('data-toggle');
			if( $( this ).is('[data-child-filter]') ) {
				//$('#' + tog).foundation('toggle');
				var child = $( this ).attr('data-child-filter');
				//$('.sub-filter:not(#' + child + ')').addClass('hide');
				$('#' + child).removeClass('hide');
			}
		});
	});
	// use value of search field to filter
	var $quicksearch = $('.search-field').keyup( debounce( function() {
		 if( $(this).val() ) {
			qsRegex = new RegExp( $quicksearch.val(), 'gi' );
			lcqs = $quicksearch.val().replace(/\s+/g, '-').toLowerCase();
			lcqs = new RegExp( lcqs, 'gi' );
			$grid.isotope();
			//get Filter Elements
			filterElems = $grid.isotope('getFilteredItemElements');
			$.each(filterElems, function( index, value ) {
				//toggle class for checker board
				var method = index % 2 ? 'removeClass' : 'addClass';
				if($('body').hasClass('post-type-archive-service')){
						$( value )[ method ]('service-grey-archive');
					} else {
						$( value )[ method ]('post-text-archive');
					}
				//$( value )[ method ]('post-text-archive');
			});
			//no results message
			if ( !$grid.data('isotope').filteredItems.length ) {
				$('#msg-box').removeClass('hide');
			} else {
				$('#msg-box').addClass('hide');
			}
		 } else {
			 qsRegex = false;
		 }
	}) );
	// debounce so filtering doesn't happen every millisecond
	function debounce( fn, threshold ) {
		var timeout;
		threshold = threshold || 100;
		return function debounced() {
			clearTimeout( timeout );
			var args = arguments;
			var _this = this;
			function delayed() {
				fn.apply( _this, args );
			}
			timeout = setTimeout( delayed, threshold );
	  };
	}
	
	//ajax load more func
	$('.misha_loadmore').click(function(){
		if( $('body.post-type-archive-service').length ){
		 postType = 'service';
		} else {
			 postType = 'none';
		}

		initialIDs = JSON.stringify(initialIDs);
		
		filterElems = $grid.isotope('getFilteredItemElements');
		$.each(filterElems, function( index, value ) {
			if(value.id){
			//push filter ids to array for loadmore
			pid = $(value).attr('id').replace(/post-/, '');
			found = $.inArray(pid, filterIDs);
				if (found < 0) {
					// Element was not found, add it.
					filterIDs.push(pid);
				}
			}
		});
		filterIDs = JSON.stringify(filterIDs);
		
		var button = $(this);
		
		if(qsRegex){
			console.log('search active sending:');
			
			
			$.ajax({
			url : misha_loadmore_params.ajaxurl,
			data : {
				action : 'searchload',
				query: initialQuery,
				cat : filterApplied,
				initial : initialIDs,
				filtered : filterIDs,
				search : $quicksearch.val(),
				ptype : postType
			},// form data
			dataType : 'json', // this data type allows us to receive objects from the server
			type : 'POST',
			beforeSend : function(xhr){
				$('#msg-box').addClass('hide');
				button.html('<i class="fas fa-spinner fa-spin"></i> Loading...'); // change the button text, you can also add a preloader image
			},
			success : function( data ){
					button.html('More posts');
					// when filter applied:
					// set the current page to 1
					misha_loadmore_params.current_page = 1;

					// set the new query parameters
					misha_loadmore_params.posts = data.posts;

					// set the new max page parameter
					misha_loadmore_params.max_page = data.max_page;

					// change the button label back
					//$('#misha_filters').find('button').text('Apply filter');

					// insert the posts to the container
					//$('#misha_posts_wrap').html(data.content);
					var $items = $(data.content);
					$grid.append( $items  ).isotope( 'appended', $items  );
					$grid.imagesLoaded().progress( function() {
						$grid.isotope('layout');
					});
					// hide load more button, if there are not enough posts for the second page
					if ( data.max_page < 2 ) {
						button.addClass('hide'); // if last page, remove the button
						$('#end-box').removeClass('hide');
					} else {
						button.text('Load More');
					}
				}
			});

		}else if(filterApplied){
			
			console.log('filter active');
			
			$.ajax({
			url : misha_loadmore_params.ajaxurl,
			data : {
				action : 'filterload',
				//query: initialQuery,
				cat : filterApplied,
				initial : initialIDs,
				filtered : filterIDs,
				ptype : postType
			},// form data
			dataType : 'json', // this data type allows us to receive objects from the server
			type : 'POST',
			beforeSend : function(xhr){
				$('#msg-box').addClass('hide');
				button.html('<i class="fas fa-spinner fa-spin"></i> Loading...'); // change the button text, you can also add a preloader image
			},
			success : function( data ){
					button.html('More posts');
					// when filter applied:
					// set the current page to 1
					misha_loadmore_params.current_page = 1;

					// set the new query parameters
					misha_loadmore_params.posts = data.posts;

					// set the new max page parameter
					misha_loadmore_params.max_page = data.max_page;

					// change the button label back
					//$('#misha_filters').find('button').text('Apply filter');

					// insert the posts to the container
					//$('#misha_posts_wrap').html(data.content);
					var $items = $(data.content);
					$grid.append( $items  ).isotope( 'appended', $items  );
					$grid.imagesLoaded().progress( function() {
						$grid.isotope('layout');
					});
					filterElems = $grid.isotope('getFilteredItemElements');
					//console.log(filterElems);
					$.each(filterElems, function( index, value ) {
							var method = index % 2 ? 'removeClass' : 'addClass';
						if($('body').hasClass('post-type-archive-service')){
							$( value )[ method ]('service-grey-archive');
						} else {
							$( value )[ method ]('post-text-archive');
						}
						//	$( value )[ method ]('post-text-archive');
					});
					// hide load more button, if there are not enough posts for the second page
					if ( data.max_page < 2 ) {
						button.addClass('hide'); // if last page, remove the button
						$('#end-box').removeClass('hide');
					} else {
						button.text('Load More');
					}
				}
			});
		} else {
			//axax call
			$.ajax({ // you can also use $.post here
				url : misha_loadmore_params.ajaxurl, // AJAX handler
				data : {
					'action': 'loadmore', // the parameter for admin-ajax.php
					'query': initialQuery, // loop parameters passed by wp_localize_script()
					'page' : misha_loadmore_params.current_page, // current page
					'ptype' : postType
				},
				type : 'POST',
				beforeSend : function ( xhr ) {
					$('#msg-box').addClass('hide');
					button.html('<i class="fas fa-spinner fa-spin"></i> Loading...'); // change the button text, you can also add a preloader image
				},
				success : function( res ){
					if( res ) { 
						button.html('More posts'); // change the button text, you can also add a preloader image
						var $items = $(res);
						$grid.append( $items  ).isotope( 'appended', $items  );
						$grid.imagesLoaded().progress( function() {
							$grid.isotope('layout');
						});
							filterElems = $grid.isotope('getFilteredItemElements');
						//console.log(filterElems);
						$.each(filterElems, function( index, value ) {
								var method = index % 2 ? 'removeClass' : 'addClass';
								if($('body').hasClass('post-type-archive-service')){
									$( value )[ method ]('service-grey-archive');
								} else {
									$( value )[ method ]('post-text-archive');
								}	
							//$( value )[ method ]('post-text-archive');
						});
							// Append Val
						//$container.append(val).isotope( 'appended', val );
						misha_loadmore_params.current_page++;

						button.text('Load More');
						if ( misha_loadmore_params.current_page == misha_loadmore_params.max_page ){ 
							button.addClass('hide'); // if last page, remove the button
							$('#end-box').removeClass('hide');
						}

					} else {
						button.addClass('hide'); // if no data, remove the button as well
						$('#end-box').removeClass('hide');

					}
				},
			});
		}
		filterIDs = JSON.parse(filterIDs);
		initialIDs = JSON.parse(initialIDs);
	});
	
	$('#deep-search').on('closeme.zf.dropdown', function() {
		var elemCount = $grid.isotope('getItemElements')
		$('.post-count').text(elemCount.length - 1);
	});
	
	//RSVP privacy and newsletter
	var chkbox = $("#eventPrivacy"),
	button = $(".rsvp-btn");
	button.attr("disabled","disabled");
	chkbox.change(function(){
		if(this.checked){
			button.removeAttr("disabled");
		}else{
			button.attr("disabled","disabled");
		}
	});
});